.lightbtn {
    background-color: #a86c4e !important;
}

.darkbtn {
    background-color: #5a77bb !important;
}

.btnbg {
    border: 1px solid black;
    color: #a86c4e;
    font-size: 16px;
    font-weight: 800;
}