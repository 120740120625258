body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.owl-theme .owl-nav {
    position: absolute !important;
    width: 100% !important;
    left: 0 !important;
    bottom: -228px !important;
}

.owl-theme .owl-nav [class*=owl-] {
    background: transparent !important;
    margin: 0 10px !important;
    padding: 0 !important;
    font-size: 35px !important;
    color: #5fa775 !important;
    opacity: 0.5 !important;
}

.login-box {
    border: 1px solid greenyellow;
    border-radius: 2px;
    box-sizing: border-box;
    width: 50%;
    height: 100%;
}