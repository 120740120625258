
.card {
    margin: 10px !important;
    /* background-color:#F2e9e6; */
}

.lightcard {
    background-color:#f2e9e6 !important;
}

.darkcard {
    background-color:#c3cee5 !important;

}